.ticket-item {
    border-bottom: 1px solid #ccc;
    padding: 0.73rem 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ticket-item-head {
    font-weight: 800;
    border-bottom: none;
    padding: 0.8rem 0.5rem 0.2rem 0.5rem;
    cursor: default;
    font-size: 1.2rem;
    border-bottom: 1px solid lightgrey;
}


.rule-item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}

.ticket-item-center {
    justify-content: center;
}

.ticket-item-link {
    text-decoration: none;
    color: black;
}

.ticket-item:hover,  .rule-item:hover{
    background-color:  #f2f3f5;
}

.active-ticket-item { 
    background-color:  #e6f2f5;
}
.active-ticket-item:hover { 
    background-color:  #d4e9ee;
}

.check-icon {
    margin-right: 2%;
}

.red-text {
    color: #ff9797;
    opacity: 1!important;
}

.green-text {
    color: #a3d9a3;
    opacity: 1!important;
}

/* .error-badge {
    margin-right: 3%;
    color: #fff;
    background-color: #f44336;
    border-radius: 50%;
    padding: 2px 8px;
    text-align: center;
} */