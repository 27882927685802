* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Style the table header */
th {
  background-color: #f2f2f2;
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Style alternating rows */
tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Style table cells */
td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Hover effect on rows */
tr:hover {
  background-color: #e0e0e0;
}

.mx-05 {
  margin: 0 0.5rem;
}

.mx-1 {
  margin: 0 1rem;
}

.my-05 {
  margin: 0.5rem 0;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-22 {
  margin-bottom: 2.2rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

body {
  background-color: #f8f8f8;
}

a {
  text-decoration: none;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.red-font {
  color: red;
}

.flex-container {
  display: flex;
}

.flex-left {
  justify-content: start;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shared-card {
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.06) 0px 3px 4px 0px, rgba(0, 0, 0, 0.06) 0px 1px 8px 0px;
  background-color: rgb(255, 255, 255);
  margin: 0 8px 20px 8px;
  padding: 8px;
  padding-bottom: 1.2rem;
  border-radius: 15px;
  border: 1px solid #d3d3d3;
}

.vk-card {
  padding: 0 8px;
  margin-bottom: 40px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  margin-top: 2rem;
  max-width: 1240px;
  margin: 0 auto;
  grid-template-areas:
    "dash-board side-bar side-bar"
    "video-section-main-page  "
    "footer footer footer";
  position: relative;
}

.dash-board {
  grid-column: 2 / 9;
  grid-row: auto;
  grid-area: "dash-board";
}

.side-bar {
  grid-column: 9 / 12;
  grid-row: auto;
  grid-area: "side-bar";
}

.video-section-main-page {
  grid-column: 2 / 12;
  grid-row: auto;
  grid-area: "video-section-main-page";
}

main {
  margin-top: 4.4rem;
}

.content-block {
  padding: 0;
  border-radius: 15px;
  grid-column: 9 / 12;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  background-color: rgb(255, 255, 255);
  /* margin: 0 8px; */
  border-radius: 15px;
  border: 1px solid #d3d3d3;
  overflow: hidden;
  height: fit-content;
}

.content-block:not(:first-child) {
  margin-top: 1rem;
}

.adblock-after-task {
 /*  background-color: #282c34;
  height: 400px; */
  margin: 50px 8px 30px 8px;
  max-height: 400px;
}

.adblock-after-task-only-mobile {
  display: none;
}
.only-mobile {
  display: none;
}

.video-section-random {
/*   background-color: #282c34;
  height: 400px; */
}

@media only screen and (max-width: 660px) {
  .grid-container {
    display: flex;
    flex-direction: column;
  }
  main {
    margin-top: 2.5rem;
  }
  .shared-card {
    box-shadow: none;
    margin: 0;
    padding: 8px;
    border-radius: none;
    border: none;
  }

 /*  .shared-card:not(:first-child) {
    border-top: 10px solid #f8f8f8;
  } */

  body {
    background-color: white;
  }
  .vk-card {
    padding: 0;
  }

  .mobile100vh {
    min-height: 100vh;
    margin-bottom: 2rem;
  }

  .adblock-after-task {
    margin: 15px 8px 30px 8px;
    max-height: 400px;
  }

  .adblock-after-task-only-mobile {
    display: block;
    margin: 0 8px 30px 8px;
    max-height: 400px;
    
    background-color: rgb(199, 199, 199);
    width: 100%;
    height: 400px;
  }
}
