.main-header {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #0267bf;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 100;
}

.main-header-content {
  width: 1015px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-header-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
    /* display: none; */
  }
}

@media only screen and (max-width: 600px) {
  .main-header {
    height: 2.9rem;
  }
}
