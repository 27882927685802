.app-mini-icon-margin {
  margin-right: 7px;
}

.android-color {
  color: #a4c639;
}

.w25 {
  width: 25px;
  font-size: 26px;
  margin-left: 0.8rem;
}

.instruction {
  display: flex;
  align-items: center;
}

.instruction-text {
  margin-left: 10px;
  text-align: left;
}

.instruction-description {
  padding: 0 0.2rem;
}
