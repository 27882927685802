.side-bar-tickets {
  padding: 0;
  border-radius: 15px;
  grid-column: 9 / 12;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  background-color: rgb(255, 255, 255);
  /* margin: 0 8px; */
  border-radius: 15px;
  border: 1px solid #d3d3d3;
  overflow: hidden;
  height: fit-content;
}
.side-bar-scroll {
  overflow: hidden;
  overflow-y: scroll;
  min-height: 0;
  max-height: 260px;
}

.side-bar-by-theme-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  cursor: pointer;
  padding: 1rem;
  margin-bottom: 1.1rem;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

/* .side-bar-by-theme-item:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
} */

.tabs {
  display: flex;
  justify-content: space-around;
  background-color: #ececec;
}

.tabItem {
  width: 100%;
  text-align: center;
}

.active-tab {
  background-color: white;
  font-weight: bold;
}

.non-active-tab-left {
  -webkit-box-shadow: inset -8px -8px 20px -15px rgba(143, 143, 143, 0.64);
  -moz-box-shadow: inset -8px -8px 20px -15px rgba(143, 143, 143, 0.64);
  box-shadow: inset -8px -8px 20px -15px rgba(143, 143, 143, 0.64);
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.non-active-tab-right {
  -webkit-box-shadow: inset 8px -8px 20px -15px rgba(143, 143, 143, 0.64);
  -moz-box-shadow: inset 8px -8px 20px -15px rgba(143, 143, 143, 0.64);
  box-shadow: inset 8px -8px 20px -15px rgba(143, 143, 143, 0.64);
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.tabs-link {
  display: block;
  padding: 0.7rem 0;
}


.loader-tabs {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .side-bar-tickets {
    margin: 0;
    box-shadow: none;
  }
  .side-bar-by-theme-item:hover {
    box-shadow: none;
  }
  /* .side-bar-scroll {
    max-height: 600px;

  } */
}
