.display-none {
  display: none;
}

.question {
  /* margin: 1.5rem 0.5rem; */
  margin: 0 0.5rem 1.1rem 0.5rem;
  /* font-size: 1.22rem; */
  font-size: 1.1rem;
}

.question-badje {
  display: inline-block;
  background-color: #0067bf;
  color: white;
  border-radius: 4px;
  padding: 0.2rem 0.3rem;
}

.hint-button {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100px;
  text-align: center;
  margin: 5px 0.5rem;
  padding: 0.5rem;
  background-color: rgb(248, 248, 166);
  cursor: pointer;
}

.hint-button:hover {
  background-color: rgb(255, 255, 103);
}

.hint-text {
  margin: 1rem 0.5rem;
  background-color: rgb(248, 248, 166);
  padding: 1rem;
}

.controll-buttons {
  display: flex;
  justify-content: center;
  align-content: center;
}

.next-button {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 200px;
  text-align: center;
  margin: 5px 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  align-self: center;
}
/* .next-button:hover {
  background-color: #edf1fb;
} */

.controll-buttons-col {
  flex-direction: column;
}

.ticket-body-thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100px;
  margin-bottom: 0.8rem;
}

.ticket-body-thumbnail-placeholder {
  position: absolute;
  top: 0;
  left: 0.5rem;
  width: calc(100% - 1rem);
  height: 100%;
  background-color: lightgrey;
  animation: skeletonloading 1.6s infinite ease-in-out;
}

.ticket-body-thumbnail-placeholder.hidden {
 animation: fadeOut 0.25s normal ease-in-out;
 opacity: 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .controll-buttons {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .question {
    margin: 0 0.5rem 0.5rem 0.5rem;
  }
  .ticket-body-thumbnail {
    margin-bottom: 0.65rem;
  }
 
  .ticket-body-thumbnail-placeholder {
    width: 100%;
    left: 0;
  }
}

@media only screen and (max-width: 305px) {
  .hint-button {
    align-self: center;
  }
  .controll-buttons {
    flex-direction: column;
  }
}

@media (hover: hover) and (pointer: fine) {
  .next-button:hover {
    background-color: #edf1fb;
  }
}

@media (hover: none) {
  .next-button:active {
    background-color: #edf1fb;
  }
}
