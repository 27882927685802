.ticket-header {
  padding: 0.3rem 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
}

.ticket-header i {
  font-size: 1.5rem;
  color: rgb(160, 160, 160);
  cursor: pointer;
}


.easy-pointer {
  padding: 0.35rem 1rem 0.35rem 0.2rem;
}

.easy-pointer:hover > i {
  color: rgb(68, 68, 68);
}
/* .dash-header {
  margin-right: 2.2rem;
} */

.timer {
  /* color: white; */
  letter-spacing: 1px;
  width: 51px;
  height: 20px;
  text-align: center;
  font-weight: bold;
}

.error-header {
  font-size: 1.6rem;
  margin-right: 2.2rem!important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  
  .easy-pointer:hover {
    background-color: unset !important;
    color: unset !important;
  }
}