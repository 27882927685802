.main-navigation__menu-btn {
  width: 3rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:flex-end;
  padding: 0;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
  font-size: 1.1rem;
}

.main-navigation__title #main-navigation-date-link{
  font-size: 1.1rem;
}

.main-navigation-link {
  padding: 0.6rem 0;
}

.main-navigation-logo {
  width: 2rem;
  height: 2rem;
  display: block;
}

.main-navigation-link:hover {
  text-decoration: underline;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
  font-family: helvetica;
  letter-spacing: 0.4px;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

.categories-context-menu-info {
  font-weight: bold;
  color: white;
  padding-left: 6px;
}

@media (min-width:600px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

@media (max-width:290px) {
  .main-navigation__title {
    font-size: 1.15rem;
  }
  #main-navigation-home-link{
    display: none;
  }
}

@media (max-width:250px) {
  .main-navigation__title {
    font-size: 1rem;
    margin-right: 5px;
  }
  #main-navigation-home-link{
    display: none;
  }
  #main-navigation-date-link{
    display: none;
  }
}
