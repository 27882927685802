.footer {
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.footer-container {
  max-width: 1050px;
  margin: 0 auto;
  padding: 3rem 20px 3rem 20px;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-column {
  flex: 1;
  margin-bottom: 20px;
  padding: 0 15px; /* Add padding */
}

.footer-heading {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}

.footer-text {
  font-size: 1rem;
  margin: 1rem 0 0 0;
}

.footer-list {
  list-style-type: none;
  padding: 0;
}


.footer-list-item {
  margin-bottom: 0.5rem;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 3px 0;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-bottom {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 10px 0;
}

.footer-link:hover {
  cursor: pointer;
}
.footer-logo-item{
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo-header{
  color: #fff;
  font-size: 1.5rem;
  margin: 0;
}

.footer-logo-img{
  display: block;
  width: 48px;
  height: 48px;
  margin-left: 1rem;
}

/* Media Queries for Adaptiveness */
@media screen and (max-width: 768px) {
  .footer-column {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .footer-container{
    padding: 3rem 8px 3rem 8px;
  }
}
