.video-section-nav {
  background-color: #ffffff;
  position: fixed;
  height: 100vh;
  z-index: 10;
  width: 150px;
  top: 0;
  margin-top: 1.1rem;
}

.video-section-main {
  margin-left: 11rem;
  margin-right: 1rem;
}

.video-section-nav-item {
  padding: 0.75rem 1.25rem;
  display: block;
  color: #030303;
}

.video-section-exit {
  margin-top: 4rem;
}

.video-section-nav-item-activ {
  background-color: #acdaff;
  position: relative;
}

.video-section-nav-item-activ::after {
  content: "";
  background-color: #0267bf;
  width: 6px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.video-section-nav-item:hover {
  color: rgb(0, 0, 0);
  background-color: rgb(232, 245, 255);
}

.video-section {
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 20px;
  margin: 0 auto;
  margin-bottom: 2.5rem;
}

@media only screen and (max-width: 600px) {
  .video-section-nav {
    width: 70%;
  }
  .video-section-main {
    margin: 2.8rem 0rem 0rem 0rem;
  }

  .video-section {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
  }
}

/* styles for large screens */
@media screen and (min-width: 2500px) {
  .video-section {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }

}
