.video-preview-mini {
  display: flex;
  margin-bottom: 20px;
  width: 350px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.video-preview-maxi {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  margin: 0 auto;
}

.video-preview-maxi img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-preview-maxi-skeleton {
  display: flex;
  margin-bottom: 20px;
  width: 330px;
  height: 200px;
  flex-direction: column;
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 10px;
  animation: skeletonloading 1.3s infinite ease-in-out;
  margin: 0 auto;
}

@keyframes skeletonloading {
  0% {
    opacity: 0.35;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.35;
  }
}

.video-preview-maxi .thumbnail img {
  max-width: 100%;
}

.thumbnail {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  min-height: 200px;
}

.thumbnail img {
  max-width: 120px;
  border-radius: 10px;
}

.info {
  flex-grow: 1;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.title {
  font-size: 0.95em;
  margin: 0;
}

.channel {
  color: #454545;
  margin: 5px 0;
  font-size: 0.9em;
}

.publishedAt {
  color: #454545;
  font-size: 0.9em;
  margin: 0;
}

.one-video-layout {
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-template-rows: 1fr;
  gap: 20px;
  grid-template-areas:
    "video-player  related-videos"
    "video-player  related-videos"
    "full-width-section  full-width-section";
  margin: 0 auto;
}

.special-preview-block {
/*   background-color: aquamarine;
  height: 400px; */
  width: 100%;
  height: 100%;
  max-height: 330px;
}

.special-one-video-block {
  width: 100%;
  height: 100%;
  max-height: 360px;
  margin-bottom: 8px;
}

.video-player {
  grid-area: video-player;
}

.related-videos {
  grid-area: related-videos;
  grid-row: 1 / 4;
}

.full-width-section {
  grid-area: full-width-section;
  grid-row: auto;
  width: 100%;
  margin-top: 2.5rem;
}

.video-player {
  width: 100%;
  height: 100%;
}

.video-player-video-title {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 0.5rem;
}

.video-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: lightgrey;
}

@media screen and (max-width: 1000px) {
  .one-video-layout {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "video-player"
      "related-videos"
      "full-width-section";
  }
  .video-player {
    width: 100%;
    height: 450px;
  }
  .related-videos {
    width: 100%;
    height: 100%;
    grid-row: inherit;
  }

  .video-preview-maxi {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .thumbnail {
    margin: 0;
  }

  .special-preview-block,
  .special-one-video-block {
    height: 400px;
  }
}

@media screen and (max-width: 600px) {
  .video-preview-maxi {
    width: 100%;
    max-width: 100%;
  }

  .thumbnail {
    margin: 0;
  }

  .thumbnail img {
    border-radius: 0px;
  }

  .thumbnail img {
    max-width: 100%;
    object-fit: cover;
  }
  .video-player {
    height: 290px;
  }

  .video-player-info {
    margin: 0 0.8rem;
  }

  .video-player-video-title {
    font-size: 1.1rem;
  }

  .channel-name {
    margin: 0;
  }
}

@media screen and (max-width: 400px) {
  .video-player {
    height: 250px;
  }
}

@media screen and (min-width: 1001px) {
  .video-player {
    max-width: 1050px;
    height: 550px;
  }
}

@media screen and (min-width: 2000px) {
  .one-video-layout {
    grid-template-columns: 5fr 3fr;
  }

  .video-player {
    max-width: 1500px;
    height: 700px;
  }
  .video-preview-maxi-skeleton {
    max-width: 500px;
    height: 310px;
  }
}

/* styles for large screens */
@media screen and (min-width: 2500px) {
  .video-preview-maxi {
    max-width: 500px;
  }

  .video-preview-maxi-skeleton {
    width: 100%;
    height: 300px;
  }
}

@media screen and (min-width: 2700px) {
  .one-video-layout {
    grid-template-columns: 6fr 4fr;
  }

  .video-player {
    max-width: 2000px;
    height: 800px;
  }
}
