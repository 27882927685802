.article-container {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 1.1rem;
}

.article-container p  img {
  display: block;
  margin: 0 auto;
} 

.article-container ul {
  line-height: 1.7;
} 

.toc {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

.toc-heading {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
}

.toc-fishki {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 1.5rem;
}

.toc-li a {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #1a0dab;
  line-height: 1.7;
}

.toc-ul {
  list-style-type: none;
  margin: 0;
  margin-left: 1rem;
  padding: 0;
}

.article-container p {
  font-size: 1.1rem;
  line-height: 1.5;
  word-wrap: break-word;
}

.article-snippet {
  display: flex;
  max-width: 800px;
}

.snippet-item__image {
  width: 300px;
  height: inherit;
  overflow: hidden;
}

.articles-sticky-sidebar-block {
  position: sticky;
  top: 60px;
  max-height: 600px;
  width: 100%;
  max-width: 300px;
  margin: 0 0.5rem;

/*   height: 600px;
  background-color: #bcbcbc; */
}

.articles-sidebar-block {
 /*  height: 300px;
  background-color: #eab6b6; */
  
  width: 100%;
  max-width: 300px;
  margin: 0 0.5rem;
}

@media screen and (max-width: 768px) {
  .article-container {
    padding: 0 5px;
  }
  .toc-heading {
    font-size: 1.8rem;
  }
  .toc-fishki {
    display: block;
  }
  .article-container h2 {
    font-size: 1.4rem;
  }
  .article-container p {
    font-size: 1.1rem;
  }
 
}

@media screen and (max-width: 660px) {
  .articles-side-bar {
    display: none;
  }
}
