.react-calendar {
  width: 350px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  font-family: "Arial", sans-serif;
  line-height: 1.125em;
  border: none;
}

.react-calendar__navigation button {
  color: #0062cc;
  font-size: 1.2em;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  border: none;
  border-radius: 6px;
  outline: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6f7ff;
  cursor: pointer;
}

.react-calendar__tile--active {
  background-color: #0062cc;
  color: white;
}

.highlight-calendar {
  background-color: lightgreen;
  color: #000;
  border-radius: 0px;
}

.calendar-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  max-width: 350px;
}

.calendar-container p {
  color:#000;
  font-weight: normal;
  font-size: 1rem;
}

.calendar-container h3 {
  color:#000;

}

.close-calendar{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 101;
  color: #000;
}

.calendar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.visit-counter p {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 400px) {
  .react-calendar {
    width: 100%;
  }
}
