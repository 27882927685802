:root {
  --very-dark-grayish-blue: hsl(217, 19%, 35%);
  --desaturated-dark-blue: hsl(214, 17%, 51%);
  --light-grayish-blue: hsl(210, 46%, 95%);
  --grayish-blue: hsl(212, 23%, 69%);
  --white: hsl(0, 0%, 100%);
}

.special-article-block {
  max-height: 350px;
  width: 100%;
  
  height: 100px;
  background-color: aquamarine;
}

.article-card {
  display: flex;
  max-width: 730px;
  border-radius: 10px;
  box-shadow: 6px 6px 5px hsla(0, 0%, 0%, 0.02),
  25px 25px 20px hsla(0, 0%, 0%, 0.03), 100px 100px 80px hsla(0, 0%, 0%, 0.05);
  margin-bottom: 2.2rem;
  position: relative;
}

.article-img-box {
  display: block;
  height: 100%;
  border-radius: 10px 0 0 10px;
  max-width: 300px;
  object-fit: cover;
  background-color: var(--light-grayish-blue);
}

.article-banner {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  transition: all 0.5s ease;
}

.article-img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-card:hover .article-banner {
  transform: scale(1.1);
}

.article-content {
  background: var(--white);
  padding: 30px 35px 32px 30px;
  border-radius: 0 10px 10px 0;
}

.article-title {
  font-size: 20px;
  color: var(--very-dark-grayish-blue);
  margin-bottom: 12px;
  line-height: 1.4;
}

.article-title:hover {
  color: var(--desaturated-dark-blue);
}

.article-text {
  color: var(--desaturated-dark-blue);
  font-size: var(--fs-13);
  line-height: 1.4;
  margin-bottom: 18px;
}

.acticle-content-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author {
  display: flex;
  align-items: center;
}

.author-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.author-name {
  color: var(--very-dark-grayish-blue);
  font-size: var(--fs-13);
}

.publish-date {
  color: var(--grayish-blue);
  font-size: var(--fs-13);
}

.share {
  position: relative;
}

.share-button {
 /*  background: var(--light-grayish-blue); */
  background: #FFF;
  padding: 8px;
  border-radius: 15px;
    cursor: pointer;
    border: 1px solid grey;
 /*  font-size: 20px; */
/*   color: var(--desaturated-dark-blue); */
  transition: all 0.25s ease;
}

.share-button:hover,
.share-button.active {
  background: var(--desaturated-dark-blue);
  color: var(--light-grayish-blue);
}




/*-----------------------------------*\
   #RESPONSIVE
  \*-----------------------------------*/

@media screen and (max-width: 1070px) {
  /**
     * article card responsive
     */

  .article-card {
    flex-direction: column;
    overflow: hidden;
  }

  .article-img-box,
  .article-content {
    border-radius: 0;
  }

  .article-img-box {
    /*  height: 180px; */
    max-width: 100%;
  }

  .article-content {
    padding: 8px 28px 20px;
  }

  .article-title {
    font-size: 20px;
  }

  .article-text {
    margin-bottom: 30px;
  }

  .acticle-content-footer {
    position: relative;
  }

  .author-avatar {
    width: 35px;
    height: 35px;
  }

  .share {
    position: static;
    z-index: 1;
  }

  .share-button {
    font-size: 16px;
  }

  .share-option {
    /**
       * variable for transforming `.share-option` box 
       * without repeating all transform value
       */
    --scale: 1;
    --translateX: 0;
    --translateY: 100%;

    inset: -20px -28px;
    border-radius: 0;
    padding: 17px 30px;
    z-index: -1;
  }

  .share-option.active {
    --translateY: 0;
  }
}
