.alert {
  position: fixed;
  z-index: 100;
  border-radius: 6px;
  top: 80px;
  right: 15px;
  width: 100%;
  max-width: 320px;
  height: 100px;
  color: white;
  padding: 10px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 26%);
  animation-duration: 0.5s;
  animation-name: slidein;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes slidein {
  from {
    right: -1000px;
  }

  to {
    right: 15px;
  }
}

.alert[goout="0"] {
  right: -1000px;
  animation: goOut 0.5s;
}

@keyframes goOut {
  from {
    right: 15px;
  }
  to {
    right: -1000px;
  }
}

.alert-unlock {
  background: rgba(81, 163, 81, 0.9);
}

.alert-unlock:hover {
  background: rgba(81, 163, 81, 0.95);
}

.achivmentAlertImg {
  width: 15%;
  margin: 0 5px;
  animation-name: rotation;
  animation-iteration-count: 2;
  animation-timing-function: linear;
  animation-duration: 1s;
}

@keyframes rotation {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  50% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .alert {
    right: 0;
    top: 70px;
    width: 100%;
  }
  .achivmentAlertImg {
    margin: 0 15px;
  }
}

@media only screen and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .alert {
    right: 0;
    top: 70px;
    width: 100%;
    word-break: break-word;
  }
  .achivmentAlertImg {
    margin: 0 15px;
  }
}
