:root {
  --select-color: #e6f2f5;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  position: static;
  width: 100%;
  background-color: #005dc2;
  color: #fff;
  text-align: center;
  margin-top: 2rem;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.26);
}

.site {
  height: 100%;
  min-height: 100vh;
  position: relative;
}


.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.button {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  text-align: center;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  align-self: center;
}
.button:hover {
  background-color: #edf1fb;
}

.button-ios-android:hover {
  background-color: #000000;
}

.controll-buttons {
  display: flex;
  justify-content: center;
  align-content: center;
}

.not-display {
  display: none;
}

.error-badge {
  margin-left: 4%;
  margin-right: 3%;
  color: #fff;
  background-color: #f44336;
  border-radius: 6px;
  padding: 0px 6px;
  text-align: center;
}

.success-badge {
  margin-left: 5px;
  color: #fff;
  background-color: #4caf50;
  border-radius: 6px;
  padding: 0px 6px;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}

.sticky {
  position: sticky;
  top: 60px;
}

.bilet-video {
  width: 100%;
  height: 400px;
}

.skeleton {
  animation: skeletonloading 1.3s infinite ease-in-out;
  width: 100%;
  min-height: 50px;
  height: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 0!important;
}

.special-theory-block {
 /*  background-color: aquamarine;
  height: 300px;
  width: 100%; */

  width: 100%;
  height: 100%;
  max-height: 350px;
}

@keyframes skeletonloading {
  0% {
    opacity: 0.35;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.35;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .answer:hover,
  .next-button:hover {
    background-color: unset !important;
  }
  .no-mobile-hover:hover {
    background-color: unset !important;
  }
  .pb-4 {
    padding-bottom: 4rem !important;
  }
  .bilet-video {
    height: 250px;
  }
}
